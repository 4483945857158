<script setup lang="ts">
import QrScanner from 'qr-scanner'

const router = useRouter()

const props = defineProps({
  showScanner: {
    type: Boolean,
    default: false,
  },
})

const video: any = document.getElementById('qr-video')
const camQrResult = document.getElementById('cam-qr-result')

function setResult(label, result) {
  scanner.stop()
  router.push(`/vehicles/${parseInt(result.data.split(':')[1])}`).then(() => {
    location.reload()
  })
}

const scanner = new QrScanner(
  video,
  (result) => setResult(camQrResult, result),
  {
    onDecodeError: (error) => {}, // eslint-disable-line
    highlightScanRegion: true,
    highlightCodeOutline: true,
  }
)

const startScanning = () => {
  scanner.start()
  scanner.setCamera('environment')
}

const stopScanning = () => scanner.stop()

watch(
  () => props.showScanner,
  () => {
    props.showScanner ? startScanning() : stopScanning()
  },
  { immediate: true }
)
</script>

<style>
.scan-region-highlight-svg,
.code-outline-highlight {
  @apply stroke-leaf !important;
}
</style>
